[data-bs-theme=light] {
  --bs-page-bg: #fcfcfc;
}

.barcode-main {
  border: 1px solid #ccc;
  display: block;
  margin: 10px auto;
  page-break-after: always;
  padding: 0.1in;
  width: 8.25in;
}

.barcode-main__barcode-item {
  border: 1px dotted #ccc;
  display: block;
  float: left;
  font-size: 12px;
  line-height: 14px;
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
  width: 38mm;
  height: 15mm;

}

.barcode-main__barcode-style {
  padding: 0.04in;
  width: 1.799in;
}

.barcode-main__barcode-style img {
  height: 26px;
  max-width: 80px;
}

.print-main {
  height: 110px;
  padding: 0.12in;
}

.print-main__print1 {
  width: 25%;
  flex: 0 0 auto;

}

.print-main__print2 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.print-main__print3 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.print-main__print4 {
  flex: 0 0 auto;
  width: 50%;
}

.print-main__print5 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.print-main__print7 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.print-main__print8 {
  flex: 0 0 auto;
  width: 50%;
}

.search-bar:first-child {
  height: 48px !important;
  position: relative !important;
}

@media (min-width: 1400px) {
  .search-bar {
    flex: 1 0 0%;
    width: auto;
  }
}

.search-bar {
  font-family: Poppins, Helvetica, "sans-serif";
  /* z-index: 999 !important; */
}


.search-bar .wrapper {
  border-radius: 4px !important;
  box-shadow: none !important;
  display: flex !important;
  flex-direction: column !important;
  font-size: 14px !important;
  padding-left: 0 !important;
  position: absolute !important;
  width: 100% !important;
  z-index: 11 !important;
  padding-top: 0 !important;
  background-color: var(--bs-gray-100);
  border-color: var(--bs-gray-100);
  color: var(--bs-gray-700);
  transition: color 0.2s ease;
  background-color: #fff;
}


.search-bar input {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background-clip: padding-box !important;
  /* background-color: #fff!important; */
  border: 0 !important;
  border-radius: 4px !important;
  color: #212529 !important;
  display: block !important;
  font-family: Poppins, Helvetica, "sans-serif" !important;
  font-size: 14px !important;
  /* font-weight: 400!important; */
  height: 42px;
  line-height: 1.5 !important;
  min-height: 42px !important;
  /* padding: 0 0 0 55px!important; */
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
  width: 100% !important;
}

.search-bar .react-search-icon {
  left: 13px !important;
  right: auto !important;
  top: 2px !important;
  z-index: 99 !important;
}


/* .image-input-placeholder {
  
} */

/*-------------------prime datatable ---------------------------*/
/* .p-column-header-content .p-column-title {
    color: #5a6276;
    font-size: 14px;
  }
  
  .p-datatable-table{
    border: 1px solid #efefef!important;
  }
  

  
  .fw-bolder {
    color: #78829d !important;
  }

  .fw-bolder-barcode {
    color: #000000 !important;
    font-size: 13px;
  }
  
  .p-sortable-column span {
    color: #99a1b7;
  }
  
  .p-sortable-column span svg {
    margin-left: 10px;
  }
  
  
  .p-paginator-bottom .p-link {
    padding: 5px 10px;
  }
  
  .p-datatable-tbody tr td{
    font-size: 14px!important;
    padding: 7px 9.75px!important;
    color: #5a6276;
    font-weight: 500;
  }
  
  .p-datatable-thead tr th{
    font-size: 13px;
    padding: 7px 9.75px!important;
    color: #78829D;
    font-weight: 600;
  }
  
  .p-inputwrapper.p-inputwrapper-filled{
    padding: 5px 0px 5px 10px;
  }
  
  .p-datatable-thead tr{
      border-bottom-width: 1px;
      border-bottom-style: dashed;
      border-bottom-color: var(--bs-border-color);
  }

  .p-paginator-page.p-highlight{
    background: #ad7d59;
    color: #fff;
    border-radius: 3px;
  } */

table>thead>tr>th,
table>tbody>tr>td {
  vertical-align: middle;
}

.p-sortable-column>.p-column-header-content [data-pc-section='sort'] svg {
  display: none;
}

.p-sortable-column.p-highlight>.p-column-header-content [data-pc-section='sort'] {
  position: relative;
}

.p-sortable-column.p-highlight>.p-column-header-content [data-pc-section='sort']::after {
  content: '';
  height: 18px;
  width: 18px;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
  opacity: 0.5;
}

.p-sortable-column.p-highlight[aria-sort="descending"]>.p-column-header-content [data-pc-section='sort']::after {
  background-image: url('../../../../public/media/icons/down-angle.svg');
}

.p-sortable-column.p-highlight[aria-sort="ascending"]>.p-column-header-content [data-pc-section='sort']::after {
  background-image: url('../../../../public/media/icons/up-angle.svg');
}

.pagination .p-paginator-element {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: .475rem;
  height: 2.5rem;
  min-width: 2.5rem;
  font-weight: 500;
  font-size: 1.075rem;
}

.p-paginator-pages {
  display: flex;
}

.p-paginator-pages .p-link.p-highlight {
  background-color: #1b84ff;
  color: #fff;
}

.pagination .p-dropdown {
  height: 2.5rem;
  min-width: 2.5rem;
  font-weight: 500;
  font-size: 1.075rem;
  /* line-height:2.5rem; */
  padding-left: 10px;
}

/*-----dashboard-color-option----------*/
.color-tbl tbody tr {
  display: flex;
  flex-wrap: wrap;
  /* gap: 12px; */
  gap: 10px 0px;
}

/* .color-tbl>tbody>tr>td {
  width: 10%;
} */

.form-group label.clr-label {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.clr {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0px;
  display: block;
}

.color-opt {
  width: 80px;
  margin-top: 0;
  margin-left: auto;
  border: 1px solid #e9e9e9;
  height: 35px;
  padding: 5px;
  border-radius: 4px;
}

.color-tbl {
  width: 100%;
}

.relative-options {
  border: 1px solid #e9e9e9;
  padding: 10px 14px;
  margin-bottom: 20px;
  border-radius: 5px !important;
}

.text-right {
  text-align: right;
}

.table-cursor {
  cursor: pointer;
}

/*-----------------------Flatpickr Calendar ------------------------*/

.flatpickr-current-month .flatpickr-monthDropdown-months {
  font-size: 14px !important;
}

.flatpickr-current-month input.cur-year {
  font-size: 15px !important;
}

/* ------------------------prime react dropdown */
.p-dropdown {
  background: #ffffff;
  border: 1px solid #d1d5db;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
  outline-color: transparent;
}

.p-dropdown {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
}

.p-dropdown .p-dropdown-trigger {
  background: transparent;
  color: #6b7280;
  width: 3rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.p-dropdown-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.p-dropdown-panel .p-dropdown-items {
  padding: 0.75rem 0;
  background-color: #fff;
  border: 1px solid #dbdfe9;
}

.p-dropdown-items {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:first-child {
  margin-top: 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 0.75rem 1.25rem;
  border: 0 none;
  color: #4b5563;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}

.p-dropdown-item {
  cursor: pointer;
  font-weight: normal;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.p-dropdown-clear-icon {
  position: absolute;
  right: 45px;
}

.p-paginator-bottom {
  margin-top: 15px;
}


/*------------------Forms inputs css--------------------*/

.form-control.form-control-solid,
.form-control {
  border-color: #cccccc;
  height: 40px;
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 13px;
  color: #000000;
  font-weight: initial;
  border: 1px solid #cccccc;
  border-bottom-color: #cccccc !important;
}

.form-control.p-inputtext, .p-multiselect-label.p-placeholder{
  height: 40px!important;
}

textarea.form-control {
  height: auto !important;
}


.form-control.form-control-search {
  border-color: #e9e9e9;
  background-color: var(--bs-gray-100);
}

.form-check.form-check-solid .form-check-input:not(:checked) {
  border: 1px solid #cccccc;
}

.btn:not(.btn-outline):not(.btn-dashed):not(.btn-bordered):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
  padding: calc(0.55rem + 1px) calc(1.5rem + 1px);
  border-radius: 4px;
}

.border {
  border-color: #e9e9e9;
}

.modal-header>.fw-bolder {
  color: #000000 !important;
  font-size: 15px;
  margin-bottom: 0;
}

.modal-header {
  padding: 1rem 1.75rem;
}

.css-13cymwt-control,
.css-t3ipsp-control {
  min-height: 40px !important;
}

.form-check-label {
  color: #25284A;
}

.ki-magnifier {
  z-index: 9;
}

.wrapper>.sc-fqkvVR>input {
  padding: 0px 0px 0px 50px !important;
}



.card {
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  border: 1px solid #F1F1F4;
}

.card-title {
  margin: 0 !important;
}

.btn.btn-light {
  color: #252F4A;
}

.btn>i.ki-filter {
  padding-right: 0px;
  color: #303030;
}

.p-datatable-emptymessage>td {
  text-align: center !important;
  padding-top: 20px;
  font-size: 14px;
  color: #858a97;
}

.accordion-button:not(.collapsed) {
  background-color: #e7e7e7;
  color: #353535;
}

.accordion-button:not(.collapsed)::after {
  filter: brightness(0);
}

.prewrap {
  white-space: pre;
}

.no-item {
  opacity: 0.25;
}

.card.disabled {
  position: relative;
}

.card.disabled::after {
  content: "Select the customer";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(175 175 175 / 62%);
  top: 0;
  left: 0px;
  z-index: 98;
  color: #fff;
  text-align: center;
  padding-top: 20px;
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border-radius: 0.625rem;
  ;
}

.custom-position {
  position: relative;
  z-index: 99;
}


.table.gs-7 th:first-child,
.table.gs-7 td:first-child {
  padding-left: 0.75rem;
}

/* ------------------------prime react autocomplete */
.p-autocomplete {
  background: none !important;
  width: 100% !important;
  border-radius: 4px !important;
  padding: 0px !important;
  border: none !important;
}

.p-autocomplete-input {
  background: none !important;
  box-shadow: none !important;
  width: 100% !important;
  height: 40px;
  padding: 0.775rem 1rem;
}

.p-autocomplete-panel {
  background: white !important;
  border-radius: 0px !important;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03) !important;
  border: 1px solid #e9e9e9 !important;
  padding: 1px !important;
}

.p-autocomplete-dd-input {
  width: 100% !important;
  border: none !important;
}

.p-autocomplete-item {
  /* padding: 3px 5px !important; */
  cursor: default;
  display: block;
  font-size: inherit;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 14px;
  color: inherit;
  padding: 8px 12px;
  box-sizing: border-box;
}

.p-autocomplete-item:hover,
.p-multiselect-item:hover {
  background-color: #DEEBFF;
}

.p-autocomplete-items-wrapper .p-autocomplete-items {
  padding: 0px !important;
  margin-bottom: 0px;
}

.p-autocomplete-items-wrapper .p-autocomplete-item .p-autocomplete-item-text {
  padding: 0px !important;
  margin: 0px !important;
  font-size: 13px !important;
  color: #25284A !important;
  font-weight: 500 !important;
  text-align: left !important;
}

.css-hlgwow{
  padding:0px 8px!important;
}


/* Add this to your CSS file */
.date-picker-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  display: flex;
  align-items: center;
}

.date-picker-clear {
  position: absolute;
  right: 30px;
  /* Adjust based on your needs */
  top: 43%;
  transform: translateY(-50%);
  border: none;
  background: transparent;
  font-size: 18px;
  color: #999;
  cursor: pointer;
  /* padding: 0 5px; */
  display: none;
  z-index: 3;
}

.date-picker-clear:hover {
  color: #666;
}

/* Show clear button only when input has value */
.date-picker-wrapper input[value]:not([value=""])~.date-picker-clear {
  display: block;
}

/* Ensure the clear button doesn't interfere with the calendar icon */
.flatpickr-input {
  padding-right: 40px !important;
}

.p-dropdown-label .p-inputtext {
  font-size: 13px;
  padding: 0 !important
}

.p-datatable-header .p-input-icon-left .p-inputtext {
  padding-left: 30px;
}

.p-datatable-header .p-input-icon-left .p-inputtext {
  padding-left: 30px;
}

.p-datatable-header .p-input-icon-left>i {
  left: 10px;
}

.profile-nav {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  height: 40px;
}

.profile-nav::-webkit-scrollbar {
  display: none;
  padding: 4px 0px;
  opacity: 0;
}

.profile-nav::-webkit-scrollbar-track {
  display: none;
}


.p-autocomplete-dropdown.p-button,
.p-multiselect-trigger {
  background: #ffffff;
  border: 0px;
  border-left: 1px solid #cccccc;
  color: #cccccc;
}

.p-autocomplete-dropdown.p-button:focus {
  box-shadow: none;
}

@media (max-width:614px) {
  .statement-card>.card-header>.card-title {
    margin-top: 10px;
  }

  .statement-card>.card-header>.card-title>div {
    display: block !important;
  }

  .statement-card>.card-header>.card-title>div>div {
    display: block !important;
  }

  .statement-card>.card-header>.card-title>div>div>.input-group {
    flex-direction: column;
    gap: 4px;
  }

  .statement-card>.card-header>.card-title>div>div>.input-group>.form-control {
    width: 100%;
  }

  .download-option-btn {
    width: 100% !important;
  }
}

.date-range.input-group>.form-control {
  border-radius: 4px !important;
  /* border-bottom-left-radius: 4px!important; */
}

.p-datatable-other-charges .p-datatable-table tbody tr {
  background-color: transparent;
}

.p-datatable-other-charges .p-datatable-table tbody tr td:first-child {
  padding-left: 0;
}

.p-button-rounded {
  border-radius: 4px !important;
}

.p-calendar-wraper>.p-calendar,
.p-calendar-wraper>.p-inputnumber {
  width: 100%;
}

.p-input-wraper>.p-component {
  width: 100%;
}

.w-100>.react-datepicker-wrapper {
  width: 100%;
}

.hide {
  display: none;
}

.select-dropdown>.p-dropdown-label {
  padding: 0px;
}

.no-wrap {
  white-space: nowrap;
}

.relative {
  position: relative;
}

@media(max-width:1300px) {
  .p-datatable-tbody>tr>td {
    white-space: nowrap;
  }

  .p-datatable-thead>tr>th {
    white-space: nowrap;
  }

  .p-datatable-tbody>tr>td>.menu>.menu-item>.menu-link {
    white-space: break-spaces;
  }
}

.p-datatable-other-charges .p-datatable-table tbody tr td:nth-child(1) {
  width: 190px;
  padding-right: 0px
}

.p-datatable-other-charges .p-datatable-table tbody tr td:nth-child(2) {
  width: 135px;
  padding-right: 0px
}

.p-datatable-other-charges .p-datatable-table tbody tr td:nth-child(3) {
  padding-right: 0px;
}

.p-datatable-other-charges .p-datatable-table tbody tr td:last-child {
  width: 100px;
  text-align: right;
  padding-right: 0px;
}

.p-autocomplete-dd-input.p-0 {
  padding: 0px !important;
}

ul.p-multiselect-items {
  padding: 0px;
}

.p-multiselect {
  height: auto !important
}

.p-multiselect-label.p-placeholder {
  padding: 0.775rem 1rem;
}

.p-multiselect-trigger,
.p-autocomplete-dropdown {
  max-width: 35px;
  width: 100%;
}

.css-1u9des2-indicatorSeparator {
  margin: 0px !important;
}