.custom-calendar {
    border: 1px solid #e4e6ef; 
    border-radius: 0.475rem; 
    padding: 4px;
    width: 100%;
    cursor: pointer;
  }
  
  .custom-calendar .p-inputtext {
    font-size: 1.2rem; 
    color: #495057; 
    background-color: transparent;
    border: none;
    box-shadow: none;
    cursor: pointer;
  }
  
  .custom-calendar .p-calendar {
    width: 100%; 
  }
  
  .custom-calendar .p-calendar .p-inputtext {
    border: none; 
    box-shadow: none; 
  }